import React, { useRef, useEffect, useState } from 'react';
import * as S from './NavMain.style';
import {
  VARIANTS_CONTAINER_SLIDE_DOWN,
  VARIANTS_INDIVIDUAL_SLIDE_DOWN,
} from '../../constants';
import DarkToggle from '../DarkToggle/DarkToggle';
import useDocumentScrollThrottled from '../../utils/useDocumentScroll';

function NavMain({ links, page }) {
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [shouldShowNormalHeader, setShouldShowNormalHeader] = useState(true);

  const MINIMUM_SCROLL = 40;
  const TIMEOUT_DELAY = 200;

  useDocumentScrollThrottled((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setShouldShowNormalHeader(currentScrollTop === 0);

    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const [isMenuOpen, setMenuOpen] = useState(false);
  const navIconRef = useRef(null);
  const navOverlayRef = useRef(null);
  function handleNav() {
    navIconRef.current.classList.toggle('open');
    navOverlayRef.current.classList.toggle('show');
    setMenuOpen(!isMenuOpen);
  }

  return (
    <S.Wrapper>
      <S.NavOverlay ref={navOverlayRef} isMenuOpen={isMenuOpen}>
        <S.NavContainer>
          {isMenuOpen ? (
            <S.List
              variants={VARIANTS_CONTAINER_SLIDE_DOWN}
              initial={'before'}
              animate={'after'}
            >
              {links.map((link, index) => (
                <S.ListItem
                  key={index}
                  variants={VARIANTS_INDIVIDUAL_SLIDE_DOWN}
                >
                  <S.Link
                    to={link.to}
                    activeClassName="link-active"
                    partiallyActive={link.text.toLowerCase() != 'home'}
                  >
                    {link.text}
                  </S.Link>
                </S.ListItem>
              ))}
            </S.List>
          ) : null}
        </S.NavContainer>
      </S.NavOverlay>
      <S.Controls
        show={!shouldHideHeader}
        normalHeader={shouldShowNormalHeader}
        page={page}
      >
        <S.NavIcon className="nav-icon" ref={navIconRef} onClick={handleNav}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </S.NavIcon>
        <DarkToggle />
      </S.Controls>
    </S.Wrapper>
  );
}

export default NavMain;
