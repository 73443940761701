import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Link as GLink } from 'gatsby';

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 999;
`;

export const NavOverlay = styled.div`
  height: 100%;
  min-width: 100%;
  background-color: #111517;
  transition: opacity ${(props) => props.theme.animations.s} ease-in-out;
  opacity: ${(props) => (props.isMenuOpen ? 1 : 0)};
  pointer-events: ${(props) => (props.isMenuOpen ? 'auto' : 'none')};
`;

export const NavContainer = styled.div`
  margin-left: 30px;
  margin-top: 30px;
  @media (min-width: 768px) {
    margin-left: 60%;
    margin-top: 100px;
  }
`;

export const List = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled(motion.li)`
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  line-height: 1;

  @media (min-width: 768px) {
    margin-bottom: 25px;
  }
`;

export const Link = styled(GLink)`
  font-family: 'Trump Gothic East', sans-serif;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;

  :hover {
    transition: color ${(props) => props.theme.animations.xs} ease-in-out;
    color: var(--color-link-darkbg);
  }
  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

export const NavIcon = styled.div`
  pointer-events: all;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: ${(props) => (props.normalHeader ? '10px' : '10px')};
  padding-right: 30px;
  pointer-events: auto;
  font-size: 35px;
  color: #fff;
  background: ${(props) => {
    if (props.page === 'home') {
      return props.normalHeader ? 'transparent' : 'rgba(17, 21, 23, 0.9)';
    }
    return props.normalHeader ? '#111517' : 'rgba(17, 21, 23, 0.9)';
  }};
  width: 100%;
  margin-top: ${(props) => (props.show ? '0' : '-60px')};
  transition: padding 300ms ease-in-out, margin-top 200ms ease-in-out,
    background-color 200ms ease-in-out;
  pointer-events: none;
`;
