import React from 'react';
import { Link } from 'gatsby';
import * as S from './Footer.style';
import { FaGithub, FaLinkedin, FaMedium, FaYoutube } from 'react-icons/fa';

const icons = [
  {
    name: 'GitHub',
    icon: <FaGithub alt="GitHub" />,
    to: 'https://www.github.com/martink-rsa',
  },
  {
    name: 'Medium',
    icon: <FaMedium alt="Medium" />,
    to: 'https://medium.com/@martink_rsa',
  },
  {
    name: 'LinkedIn',
    icon: <FaLinkedin alt="LinkedIn" />,
    to: 'https://www.linkedin.com/in/martink-rsa',
  },
  {
    name: 'YouTube',
    icon: <FaYoutube alt="YouTube" />,
    to: 'https://www.youtube.com/channel/UC6Yp7xFI2SZJQxmvzwh4myA/',
  },
];

function Footer() {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Cell>
          <S.LinksList>
            <S.ListItem horizontal>
              <Link to="/" activeClassName="link-active">
                HOME
              </Link>
            </S.ListItem>
            <S.ListItem horizontal>
              <Link
                to="/blog"
                activeClassName="link-active"
                partiallyActive={true}
              >
                BLOG
              </Link>
            </S.ListItem>
          </S.LinksList>
        </S.Cell>
        <S.Cell>
          © {new Date().getFullYear()}{' '}
          <a href="mailto:martin@martinkruger.com">Martin Kruger</a>
        </S.Cell>
        <S.Cell>
          <S.List>
            {icons.map((icon, index) => (
              <S.ListItem key={index} horizontal>
                <a
                  href={icon.to}
                  aria-label={icon.name}
                  title={icon.name}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {icon.icon}
                </a>
              </S.ListItem>
            ))}
          </S.List>
        </S.Cell>
      </S.Container>
    </S.Wrapper>
  );
}

export default Footer;
