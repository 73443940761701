import styled from 'styled-components';
import { motion } from 'framer-motion';
import { IoIosSunny, IoMdMoon } from 'react-icons/io';

export const Label = styled.label`
  cursor: pointer;
  pointer-events: all;
`;

export const Input = styled.input`
  display: none;
`;

export const Sun = styled(IoIosSunny)`
  top: 0;
  height: 30px;
  margin-top: 8px;
`;

export const Moon = styled(IoMdMoon)`
  top: 0;
  height: 30px;
  margin-top: 8px;
`;

export const Icons = styled(motion.div)`
  height: 50px;
  width: 50px;
`;
export const MotionDiv = styled(motion.div)`
  position: absolute;
`;
