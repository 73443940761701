import React from 'react';
import { ThemeContext } from '../ThemeContext';
import * as S from './DarkToggle.style';

const ANIMATION_DURATION = 1;

function DarkToggle() {
  const { colorMode, setColorMode } = React.useContext(ThemeContext);
  const [isBlocked, setBlocked] = React.useState(false);
  function blockControl() {
    setBlocked(true);
    setTimeout(() => setBlocked(false), ANIMATION_DURATION * 1000);
  }

  function handleClick(event) {
    if (!isBlocked) {
      blockControl();
      setColorMode(event.target.checked ? 'dark' : 'light');
    }
  }

  if (!colorMode) {
    return null;
  }

  return (
    <S.Label htmlFor="dark-toggle">
      <S.Input
        id="dark-toggle"
        name="dark-toggle"
        type="checkbox"
        checked={colorMode === 'dark'}
        onChange={(event) => handleClick(event)}
      />
      <S.Icons
        initial={colorMode === 'dark' ? 'on' : 'off'}
        animate={colorMode === 'dark' ? 'on' : 'off'}
      >
        <S.MotionDiv
          variants={{
            off: { scale: [0.6, 1], opacity: [0, 1], marginTop: [40, 0] },
            on: { scale: [1, 0.6], opacity: [1, 0], marginTop: [0, -40] },
          }}
          transition={{
            duration: ANIMATION_DURATION,
            ease: 'easeInOut',
            // times: [0, 1],
          }}
        >
          <S.Sun />
        </S.MotionDiv>
        <S.MotionDiv
          variants={{
            off: { scale: [1, 0.6], opacity: [1, 0], marginTop: [0, -40] },
            on: { scale: [0.6, 1], opacity: [0, 1], marginTop: [40, 0] },
          }}
          transition={{
            duration: ANIMATION_DURATION,
            ease: 'easeInOut',
            // times: [0, 1],
          }}
        >
          <S.Moon />
        </S.MotionDiv>
      </S.Icons>
    </S.Label>
  );
}

export default DarkToggle;
