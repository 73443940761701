import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 20px;
  text-align: center;
  background: var(--color-background3);
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px 30px;
  color: #fff;
  margin: 0 auto;
  max-width: 764px;
  width: 100%;
  div:nth-child(2) {
    font-size: 14px !important;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    flex-direction: row;
  }
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
export const LinksList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin-right: 10px;
  }
`;

export const ListItem = styled.li`
  margin: 0;
  padding: 0;
  display: ${(props) => (props.horizontal ? 'inline-block' : 'horizontal')};
`;

export const Cell = styled.div`
  width: 100%;

  a {
    color: #fff;
    transition: color 150ms ease-in-out;
  }
  a:hover {
    color: var(--color-link-darkbg);
  }
  svg {
    font-size: 30px;
    color: #fff;
    transition: color 150ms ease-in-out;
    margin-left: 10px;
    :hover {
      color: var(--color-link-darkbg);
    }
  }
  :first-of-type {
    text-align: center;
  }
  :last-of-type {
    text-align: center;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    width: 33%;
    :first-of-type {
      text-align: left;
    }
    :last-of-type {
      text-align: right;
    }
  }
`;
